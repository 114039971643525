import { Link } from 'react-router-dom';
import { Instagram, Mail, Globe, Shield, Book } from 'lucide-react';

function Footer() {
  return (
    <footer className="py-16 text-white bg-gray-900">
      <div className="container px-4 mx-auto">
        <div className="grid grid-cols-1 gap-12 mb-12 md:grid-cols-3">
          <div className="text-center md:text-left">
            <h3 className="mb-6 text-2xl font-bold text-blue-400">About Gēo</h3>
            <p className="text-lg leading-relaxed">Gēo is more than an app. It's a revolutionary location-based social network that brings your city to life, connecting you with your community and local experiences in real-time.</p>
          </div>
          <div className="text-center md:text-left">
            <h3 className="mb-6 text-2xl font-bold text-blue-400">Legal</h3>
            <ul className="space-y-4">
              <li><Link to="/privacy-policy" className="flex items-center justify-center text-lg transition-colors duration-300 hover:text-blue-400 md:justify-start"><Shield className="w-5 h-5 mr-2" /> Privacy Policy</Link></li>
              <li><Link to="/eula" className="flex items-center justify-center text-lg transition-colors duration-300 hover:text-blue-400 md:justify-start"><Book className="w-5 h-5 mr-2" /> EULA</Link></li>
            </ul>
          </div>
          <div className="text-center md:text-left">
            <h3 className="mb-6 text-2xl font-bold text-blue-400">Connect</h3>
            <ul className="space-y-4">
              <li><a href="https://www.instagram.com/geoapp.io" target="_blank" rel="noopener noreferrer" className="flex items-center justify-center text-lg transition-colors duration-300 hover:text-blue-400 md:justify-start">
                <Instagram className="w-5 h-5 mr-2" /> Instagram
              </a></li>
              <li><a href="mailto:admin@geoapp.io" className="flex items-center justify-center text-lg transition-colors duration-300 hover:text-blue-400 md:justify-start">
                <Mail className="w-5 h-5 mr-2" /> Support
              </a></li>
            </ul>
          </div>
        </div>
        <div className="text-center">
          <p className="text-lg">&copy; {new Date().getFullYear()} Gēo by Jan. All rights reserved.</p>
          <p className="flex items-center justify-center mt-4 text-lg">
            <Globe className="w-5 h-5 mr-2" /> Your City, Alive in Real-Time
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;