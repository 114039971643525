import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ConfirmEmail: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>('Confirming your email...');
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

  useEffect(() => {
    const confirmEmail = async () => {
      const confirmationUrl = searchParams.get('confirmation_url');

      if (!confirmationUrl) {
        setMessage('Invalid confirmation link');
        return;
      }

      try {
        const response = await fetch(confirmationUrl);
        if (response.ok) {
          setMessage('Email confirmed successfully! Redirecting...');
          setIsConfirmed(true);
          setTimeout(() => {
            window.location.href = 'titan://login';
        }, 2000);
        } else {
          throw new Error('Failed to confirm email');
        }
      } catch (error) {
        console.error('Error confirming email:', error);
        setMessage('Failed to confirm email. Please try again or contact support.');
      }
    };

    confirmEmail();
  }, [searchParams, navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="p-8 text-center bg-white rounded-lg shadow-lg">
        <h1 className="mb-4 text-3xl font-bold text-blue-600">Email Confirmation</h1>
        <p className="mb-4 text-xl">{message}</p>
        {isConfirmed && (
          <div className="mt-4">
            <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-500 rounded-full">
              <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            </div>
          </div>
        )}
        {!isConfirmed && (
          <div className="mt-4">
            <div className="w-12 h-12 mx-auto border-t-4 border-blue-500 rounded-full animate-spin"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmEmail;