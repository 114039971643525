import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Instagram, MapPin, Users, Award, Globe, ChevronDown } from 'lucide-react';
import PrivacyPolicy from './PrivacyPolicy';
import Eula from './Eula';
import ResetPasswordForm from './ResetPassword';
import logo from './geo_logo.png';
import geoPreview from './geo_preview.mp4';
import './App.css';
import Footer from './Footer';
import ConfirmEmail from './ConfirmEmail';

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

function FeatureCard({ icon, title, description }: FeatureCardProps) {
  return (
    <div className="p-8 transition-all duration-300 bg-gray-900 rounded-lg hover:bg-gray-800 hover:scale-105 hover:shadow-xl">
      <div className="mb-6 text-6xl text-blue-400">{icon}</div>
      <h3 className="mb-4 text-3xl font-bold">{title}</h3>
      <p className="text-xl text-gray-300">{description}</p>
    </div>
  );
}

function App() {
  const initialCities = useMemo(() => [
    'San Juan', 'Ponce', 'Mayagüez', 'Arecibo', 'Guaynabo','Carolina', 'Caguas', 'Fajardo', 'Vega Baja', 'Manatí','Vega Alta', // Puerto Rico
    'Miami', 'Orlando', 'Tampa', // Florida
    'Richmond', 'Norfolk', 'Virginia Beach', // Virginia
    'Hartford', 'New Haven', 'Stamford', // Connecticut
    'Toronto', 'Vancouver', 'Calgary', 'Edmonton', 'Winnipeg', 'Montreal', 'Ottawa', 'Quebec City', 'Halifax', // Canada
    'New York', 'Boston', 'Washington D.C.', // USA
    'London', 'Manchester', 'Birmingham', // UK
    'Paris', 'Marseille', // France
    'Berlin', 'Hamburg', 'Munich', // Germany
    'Rome', 'Milan', 'Naples', // Italy
    'Madrid', 'Barcelona', 'Valencia', // Spain
    'Amsterdam', // Netherlands
    'Oslo', 'Bergen', // Norway
    'Lisbon', 'Porto', 'Funchal', // Portugal
    'Stockholm', // Sweden
  ], []);

  const shuffleArray = useCallback((array: string[]) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  }, []);

  const [cities, setCities] = useState(shuffleArray(initialCities));
  const [currentCityIndex, setCurrentCityIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isPaused) {
        setIsTransitioning(true);
        setTimeout(() => {
          setCurrentCityIndex(prevIndex => {
            const nextIndex = (prevIndex + 1) % cities.length;
            if (nextIndex === 0) {
              setIsPaused(true);
              setTimeout(() => {
                setCities(shuffleArray(initialCities));
                setIsPaused(false);
              }, 5000); // Pause for 5 seconds before reshuffling
            }
            return nextIndex;
          });
          setIsTransitioning(false);
        }, 1000); // 1 second transition
      }
    }, 5000); // Change city every 5 seconds

    return () => clearInterval(intervalId);
  }, [cities, isPaused, shuffleArray, initialCities]);

  return (
    <Router>
      <Routes>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/eula" element={<Eula />} />
        <Route path="/reset-password" element={<ResetPasswordForm />} />
        <Route path="/confirm-email" element={<ConfirmEmail />} />
        <Route path="/" element={
          <div className="flex flex-col min-h-screen text-white bg-black">
            <nav className="p-4 bg-black">
              <div className="container flex items-center justify-between mx-auto">
                <Link to="/" className="flex items-center">
                 <img src={logo} alt="Geo logo" className="w-8 h-8 mr-2" />
                  <span className="text-2xl font-bold text-white">Gēo</span>
                </Link>
              </div>
            </nav>
            
            <main className="container flex-grow px-4 mx-auto mt-8">
              <div className="text-center">
                <h1 className="mb-4 text-6xl font-bold tracking-tight md:text-7xl">
                  <span 
                    className={`text-blue-400 inline-block transition-all duration-1000 ${isTransitioning ? 'opacity-0 transform translate-y-8' : 'opacity-100 transform translate-y-0'}`}
                  >
                    {cities[currentCityIndex]}
                  </span>
                </h1>
                <h2 className="mb-4 text-5xl font-bold tracking-tight md:text-6xl">
                  <span className="text-white">Alive</span>
                </h2>
                <p className="mb-8 text-2xl font-light">Experience your surroundings in real-time.</p>
                
                <div className="max-w-4xl mx-auto mb-12">
                  <div className="relative mx-auto mb-8 overflow-hidden rounded-lg shadow-2xl" 
                       style={{
                         width: '100%',
                         maxWidth: '390px',
                         aspectRatio: '390 / 844',
                       }}>
                    <video 
                      autoPlay 
                      loop 
                      muted 
                      playsInline 
                      className="absolute top-0 left-0 object-cover w-full h-full"
                    >
                      <source src={geoPreview} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <p className="mb-6 text-xl leading-relaxed">
                    Curious about what's happening around you? Whether you're in your hometown or traveling, 
                    Gēo keeps you in the loop. Our interactive map shows you real-time hotspots and events, 
                    ensuring you're always part of the action.
                  </p>
                  <p className="mb-6 text-xl leading-relaxed">
                    Exploring a new city? Check out the heatmap to see where the excitement is. 
                    And if you're in a place that's not on the map yet, be the pioneer! 
                    Start sharing and bring that city to life on Gēo. Your experiences can light up a whole new urban adventure.
                  </p>
                  <p className="mb-6 text-xl leading-relaxed">
                    Proudly made in Puerto Rico 🇵🇷, Gēo is your global companion for local discoveries.
                  </p>
                </div>
                
                <div className="grid grid-cols-1 gap-8 mb-12 md:grid-cols-2">
                  <FeatureCard 
                    icon={<MapPin />} 
                    title="Discover" 
                    description="See what's active around you in real-time. From local events to popular hangouts, always be in the know." 
                  />
                  <FeatureCard 
                    icon={<Users />} 
                    title="Connect" 
                    description="Join local communities, meet like-minded people, and create lasting connections in your area." 
                  />
                  <FeatureCard 
                    icon={<Award />} 
                    title="Engage" 
                    description="Become a local expert. Share your favorite spots, review events, and help others discover the best of your city." 
                  />
                  <FeatureCard 
                    icon={<Globe />} 
                    title="Explore" 
                    description="Planning a trip? Use Gēo to explore new cities like a local, finding the most exciting and authentic experiences." 
                  />
                </div>
                
                <div className="mb-12 space-y-6">
                  <a href="https://testflight.apple.com/join/qOoY53P1" target="_blank" rel="noopener noreferrer" className="inline-block px-10 py-3 text-xl font-bold text-black transition duration-300 bg-blue-400 rounded-full shadow-lg hover:bg-blue-300 hover:scale-105">
                    Start Exploring Your City
                  </a>
                </div>

                <a href="https://www.instagram.com/geoapp.io" target="_blank" rel="noopener noreferrer" className="inline-flex items-center text-lg text-blue-400 transition-colors duration-300 hover:text-blue-300">
                  <Instagram className="w-6 h-6 mr-2" />
                  Follow Our Urban Journey
                </a>

                <div className="mt-16 animate-bounce">
                  <ChevronDown className="w-10 h-10 mx-auto text-blue-400" />
                </div>
              </div>
            </main>
            
            <Footer />
          </div>
        } />
      </Routes>
    </Router>
  );
}

export default App;